import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { CircularProgress, Typography, IconButton } from '@mui/material';
import { fetchFeaturedBlog } from '../reducers/blogSlice';
import { ArrowForward } from '@mui/icons-material';

const BlogCTA = () => {
    const dispatch = useDispatch();
    const { featuredBlog, status } = useSelector((state) => state.blogs);

    useEffect(() => {
        dispatch(fetchFeaturedBlog());
    }, [dispatch]);

    const capitalizeFirstLetter = (word) => {
        return word ? word.charAt(0).toUpperCase() + word.slice(1) : '';
    };

    return (
        <section className="container my-5" id="blog-cta">
            <div className="row align-items-center mb-4">
                <div className="col-lg-8 col-md-6 col-sm-12 d-flex align-items-center">
                    <Typography variant="h2" className="text-dark fw-medium">
                        <Link to="#blog-cta" className="text-decoration-none text-x fw-bold">
                            Featured Blog
                        </Link>
                    </Typography>
                    <span className="ms-3 badge bg-light text-x d-none d-lg-inline-block">Preview</span>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 text-end">
                    <Link className="text-decoration-none text-x d-flex align-items-center justify-content-end" to="/blog">
                    <IconButton 
                        style={{ color: '#93278F' }} // Use style for custom color
                        aria-label="next" 
                        size="large"
                        >
                            <ArrowForward/>
                        </IconButton>
                        
                    </Link>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12">
                    <div className="position-relative">
                        {status === 'loading' ? (
                            <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                                <CircularProgress color="primary" />
                            </div>
                        ) : (
                            <div className="card border-0 shadow-sm">
                                <img
                                    src={featuredBlog.thumbnail}
                                    alt={featuredBlog.title}
                                    className="card-img-top"
                                    style={{ objectFit: 'cover', height: '250px' }}
                                />
                                <div className="card-body d-flex flex-column">
                                    <Typography variant="subtitle2 text-x fw-semibold" color="light" className="text-uppercase">
                                        {capitalizeFirstLetter(featuredBlog.category)}
                                    </Typography>
                                    <Typography variant="h5" className="card-title mt-2 text-x fw-bold">
                                        {featuredBlog.title}
                                    </Typography>
                                    <Typography variant="body2" color="Secondary" className="mb-3">
                                        {featuredBlog.month} {featuredBlog.day}
                                    </Typography>
                                    <Typography variant="body1" className="card-text text-secondary mb-3">
                                        {featuredBlog.excerpt}
                                    </Typography>
                                    <Link to={`/blog/${featuredBlog.slug}`} className="btn btn-light px-4 fw-bold rounded-pill mt-auto align-self-start text-x">
                                        Continue Reading <ArrowForward fontSize="small" className="ms-2" />
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlogCTA;
