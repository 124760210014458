import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Utility to extract data from response
const extractData = (response, key = 'results') => {
  const data = response.data[key] || response.data;
  if (!Array.isArray(data)) throw new Error('Unexpected data format');
  return data;
};

// Initial State
const initialState = {
  services: [],
  activeServices: [],
  vendors: [],
  topVendors: [],
  serviceCategories: [],
  bookings: [],
  serviceDetail: null,
  status: 'idle', // Can be 'idle', 'loading', 'succeeded', 'failed'
  error: null,    // For error handling
};




// Async Thunks
export const fetchServiceCategories = createAsyncThunk(
  'services/fetchServiceCategories',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services/categories/`);
      return extractData(response);
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

export const fetchServices = createAsyncThunk(
  'services/fetchServices',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services/`);
      return extractData(response);
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

export const fetchServiceById = createAsyncThunk(
  'services/fetchServiceById',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

export const fetchVendors = createAsyncThunk(
  'services/fetchVendors',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services/vendors/`);
      return extractData(response);
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

export const fetchTopVendors = createAsyncThunk(
  'services/fetchTopVendors',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/vendors/top_vendors/`);
      return extractData(response);
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);




export const createService = createAsyncThunk(
  'services/createService',
  async (serviceData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/services/`, serviceData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

export const updateService = createAsyncThunk(
  'services/updateService',
  async ({ id, serviceData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/services/${id}/`, serviceData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

export const deleteService = createAsyncThunk(
  'services/deleteService',
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/services/${id}/`);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

// Slice Definition
const serviceSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle Service Categories
      .addCase(fetchServiceCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchServiceCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.serviceCategories = action.payload;
      })
      .addCase(fetchServiceCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Handle Services
      .addCase(fetchServices.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchServices.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.services = action.payload;
      })
      .addCase(fetchServices.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })     

      // Handle Single Service
      .addCase(fetchServiceById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchServiceById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.serviceDetail = action.payload;
      })
      .addCase(fetchServiceById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Handle Vendors
      .addCase(fetchVendors.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVendors.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.vendors = action.payload;
      })
      .addCase(fetchVendors.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Handle Top Vendors
      .addCase(fetchTopVendors.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTopVendors.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.topVendors = action.payload;
      })
      .addCase(fetchTopVendors.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

     

      // Handle Create Service
      .addCase(createService.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createService.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.services.push(action.payload);
      })
      .addCase(createService.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Handle Update Service
      .addCase(updateService.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateService.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.services.findIndex((service) => service.id === action.payload.id);
        if (index >= 0) {
          state.services[index] = action.payload;
        }
      })
      .addCase(updateService.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // Handle Delete Service
      .addCase(deleteService.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.services = state.services.filter((service) => service.id !== action.payload);
      })
      .addCase(deleteService.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

// Export actions
export const { resetError } = serviceSlice.actions;

// Export reducer
export default serviceSlice.reducer;
