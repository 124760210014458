import React from 'react';
import { CalendarToday, ShowChart, Campaign, AttachMoney, NotificationsActive } from '@mui/icons-material';
import { VerifiedUser, Payments, Chat, TrendingUp, Security } from '@mui/icons-material';

const WhyUs = () => {
  return (
    <div className="py-5 bg-light">
      <div className="container">
        <div className="row mb-4 text-center">
          <div className="col-lg-12">
            <h3 className="display-4 fw-bold text-x">Why Choose Emoments</h3>
            <p className="lead text-muted">Discover how Emoments brings convenience, trust, and collaboration to event planning.</p>
          </div>
        </div>

        {/* Section 1 */}
        <div className="mb-5">
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-1 order-2 mb-4 mb-lg-0">
              <div className="p-3">
                <h5 className="mb-3 fw-semibold text-x">Planning an Event?</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <VerifiedUser className="me-2 text-x" /> 
                    Connect with trusted service providers across various industries.
                  </li>
                  <li className="mb-2">
                    <Payments className="me-2 text-x" /> 
                    Manage bookings, contracts, and payments effortlessly.
                  </li>
                  <li className="mb-2">
                    <TrendingUp className="me-2 text-x" /> 
                    Explore budget-friendly options with instant pricing.
                  </li>
                  <li className="mb-2 text-secondary">
                    <Chat className="me-2 text-x" /> 
                    Seamlessly coordinate every detail with built-in communication tools.
                  </li>
                  <li className='text-secondary'>
                    <Security className="me-2 text-x" /> 
                    Enjoy secure payments, contracts, and peace of mind.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 order-lg-2 order-1">
              <div className="p-3">
                <img src="https://via.placeholder.com/500x300?text=Event+Planning" className="img-fluid rounded" alt="Event Planning" />
              </div>
            </div>
          </div>
        </div>

        {/* Section 2 */}
        <div>
          <div className="row align-items-center">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="p-3">
                <img src="https://via.placeholder.com/500x300?text=Service+Provider+Network" className="img-fluid rounded" alt="Service Provider Network" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="p-3">
                <h5 className="mb-3 fw-semibold text-x">Are You a Service Provider?</h5>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    <CalendarToday className="me-2 text-x" /> 
                    Stay organized with our integrated event calendar.
                  </li>
                  <li className="mb-2">
                    <ShowChart className="me-2 text-x" /> 
                    Access performance insights to help you grow and improve.
                  </li>
                  <li className="mb-2">
                    <Campaign className="me-2 text-x" /> 
                    Benefit from free marketing exposure to reach more clients.
                  </li>
                  <li className="mb-2">
                    <AttachMoney className="me-2 text-x" /> 
                    Set your rates and control your schedule and earnings.
                  </li>
                  <li>
                    <NotificationsActive className="me-2 text-x" /> 
                    Receive instant notifications to stay on top of opportunities.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
