import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchServices} from '../reducers/serviceSlice';
import { CircularProgress, Typography, Rating } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import '../styles/styles.css';

const ServiceList = () => {
    const dispatch = useDispatch();
    const { services = [], status, hasMore } = useSelector((state) => state.services);
    const [viewMode] = useState('grid');
    const { ref, inView } = useInView({ threshold: 0.1 });

    // Fetch services on initial load and when more are needed
    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchServices());
        }
    }, [dispatch, status]);

    useEffect(() => {
        if (inView && hasMore) {
            dispatch(fetchServices());
        }
    }, [inView, hasMore, dispatch]);

    const capitalizeFirstLetter = (word) => word ? word.charAt(0).toUpperCase() + word.slice(1) : '';    

    const renderServiceCards = () => {
        return services.map((service, index) => (
            <motion.div
                key={service.id}
                className={`col-md-${viewMode === 'grid' ? '4' : '12'} mb-4`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }} // Staggered fade-in effect
            >
                <div className={`card border-0 shadow-sm h-100 service-card ${viewMode}`}>
                    {service.photo && (
                        <img
                            src={service.photo}
                            className="card-img-top lazyload"
                            alt={service.title}
                            loading="lazy"
                        />
                    )}
                    <div className="card-body d-flex flex-column">
                        {service.isTopRated && (
                            <span className="badge bg-warning text-dark mb-2">Top Rated</span>
                        )}
                        {service.isPopular && (
                            <span className="badge bg-success text-light mb-2">Popular</span>
                        )}
                        <Typography variant="subtitle2" className='text-x'>
                            {capitalizeFirstLetter(service.category?.name)}
                        </Typography>
                        <Typography variant="h5" className="card-title fw-bold text-x mt-2">
                            {service.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            Price: ${service.price}
                        </Typography>
                        <Typography className="fw-bold text-x">
                            Category: {service.category || 'N/A'}
                        </Typography>
                        <Typography variant="body2" className="text-secondary mt-1">
                            Vendor: {service.vendor?.name || 'N/A'}
                        </Typography>
                        <Rating
                            value={service.vendor?.rating || 0}
                            precision={0.5}
                            readOnly
                            className="mt-2"
                        />
                        <div className="mt-auto d-flex justify-content-between align-items-center">
                            <Link to={`/services/${service.id}`}  className="btn btn-light text-x">
                                View Details
                                <ArrowForward fontSize="small" />
                            </Link>
                            <Typography variant="body2" color="textSecondary">
                                {service.updatedAt ? `${service.updatedAt}` : 'Recently Updated'}
                            </Typography>
                        </div>
                    </div>
                </div>
            </motion.div>
        ));
    };

    return (
        <div className='container mt-4'>
            <Typography variant="h3" className="text-center mb-4 fw-bold text-x">
                Our Services
            </Typography>          

            {/* Service Cards */}
            <div className="row">
                {renderServiceCards()}
            </div>

            {/* Infinite Scroll Trigger */}
            {status === 'loading' ? <CircularProgress color="primary" /> : (
                <div ref={ref} className="text-center my-4">
                    {hasMore && <CircularProgress color="primary" />}
                </div>
            )}
        </div>
    );
};

export default ServiceList;
