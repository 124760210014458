import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb,faUsers, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import CMO_Profile from '../assets/About_Us/Tonderai_ProPic.jpg';
import CTO_Profile from '../assets/About_Us/Einstein_ProPic.jpg';
import OurMission_Pic from '../assets/About_Us/ourMissionPic.jpg';
import OurVision_Pic from '../assets/About_Us/ourVisionPic.jpg';
import avatar1 from '../assets/Avatar/avatar1.jpg'


const About = () => {
    return (
        <div className="bg-page  py-5">
            <div className="container">
                <div className="row text-center mb-5 py-5">
                    <div className="col">
                        <h2 className="about-title fw-semibold display-4 fw-bold text-x" data-aos="fade-up">About Us</h2>
                        <p className="lead about-subtitle" data-aos="fade-up" data-aos-delay="200">Discover our mission, vision, and values that drive our success.</p>
                    </div>
                </div>
                <div className="row mb-5" data-aos="fade-right">
                    <div className="col-md-6 mb-4 mb-md-0">
                        <img src={OurMission_Pic} className="img-fluid about-image shadow-lg rounded col-md-8" alt="About Us" />
                    </div>
                    <div className="col-md-6 d-flex align-items-center">
                        <div className="about-text">
                            <h3 className="about-mission-title fw-semibold text-x h4"><strong>
                                Our Mission</strong></h3>
                            <p className="about-mission-text">Our vision is to revolutionize event planning and management by providing intuitive, user-friendly solutions that make organizing events effortless and enjoyable.
                                 We strive to streamline the process for everyone, ensuring a seamless experience from start to finish and transforming event management into a delightful experience.</p>
                        </div>
                    </div>
                </div>
                <div className="row mb-5" data-aos="fade-left">
                    <div className="col-md-6 order-md-2 mb-4 mb-md-0">
                        <img src={OurVision_Pic} className="img-fluid about-image shadow-lg rounded col-md-8" alt="Our Vision" />
                    </div>
                    <div className="col-md-6 d-flex align-items-center order-md-1">
                        <div className="about-text">
                            <h3 className="about-vision-title fw-semibold text-x h4"><strong>Our Vision</strong></h3>
                            <p className="about-vision-text">Our vision is to become the premier global hub for event planning and management.
                                 We aim to empower users with seamless tools and an expansive network to effortlessly organize, manage, and experience events, setting the standard for excellence and innovation in the industry.</p>
                        </div>
                    </div>
                </div>
                <div className="container px-4 py-5" id="icon-grid">
                    <h2 className="pb-2 border-bottom text-x fw-bold" data-aos="fade-up">Our Core Principles</h2>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
                        <div className="col d-flex align-items-start" data-aos="zoom-in" data-aos-delay="600">
                            <FontAwesomeIcon icon={faLightbulb} className="icon me-3 text-x" />
                            <div>
                                <h3 className="fw-bold mb-0 fs-4 text-x">Convenience</h3>
                                <p>We value convenience and aim to provide a one-stop solution for all event management needs. Our platform streamlines the process, eliminating the need to navigate multiple platforms or services.</p>
                            </div>
                        </div>
                        <div className="col d-flex align-items-start" data-aos="zoom-in">
                            <FontAwesomeIcon icon={faShieldAlt} className="icon me-3 text-x" />
                            <div>
                                <h3 className="fw-bold mb-0 fs-4 text-x">Trust</h3>
                                <p>Building trust is at the core of our business. We foster trust by carefully selecting and vetting service providers, ensuring they meet our stringent standards of quality, reliability, and professionalism.</p>
                            </div>
                        </div>
                        <div className="col d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
                            <FontAwesomeIcon icon={faLightbulb} className="icon me-3 text-x" />
                            <div>
                                <h3 className="fw-bold mb-0 fs-4 text-x">Innovation</h3>
                                <p>We embrace innovation and constantly seek new ways to enhance our platform and improve the event management experience. We stay ahead of industry trends and leverage cutting-edge technology to deliver the best solutions to our users.</p>
                            </div>
                        </div>
                        <div className="col d-flex align-items-start" data-aos="zoom-in" data-aos-delay="400">
                            <FontAwesomeIcon icon={faUsers} className="icon me-3 text-x" />
                            <div>
                                <h3 className="fw-bold mb-0 text-x fs-4">Collaboration</h3>
                                <p>We believe in the power of collaboration and effective communication. By facilitating seamless collaboration among event organizers, service providers, and attendees, we create a cohesive and successful event ecosystem.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mx-auto container shadow-lg  rounded-4 cta-x" data-aos="fade-up">
                    <div className="text-center bg-body-tertiary ">
                        <div className="p-5 text-center bg-body-tertiary rounded-3 jumbotron-content">
                            <h1 className="jumbotron-title fw-semibold fw-bold text-x">Join the Excitement!</h1>
                            <p className="col-lg-8 mx-auto fs-5 text-muted jumbotron-text">
                               Be part of the action with our vibrant events community! Whether you’re looking to attend, organize, or simply stay updated on the latest happenings, we’ve got you covered.

                               Sign up now to connect with fellow enthusiasts, get exclusive access to upcoming events, and manage your schedules effortlessly. Don’t miss out—your next adventure starts here!
                            </p>
                            <div className="d-inline-flex gap-2 mb-5">
                                <button className="d-inline-flex align-items-center btn btn-light btn-lg px-4 rounded-pill jumbotron-btn text-x fw-bold" type="button">
                                    Offer your services
                                </button>
                                <button className="btn btn-outline-secondary btn-lg px-4 rounded-pill jumbotron-btn-secondary fw-bold text-x" type="button">
                                    Book now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row text-center my-5">
                    <div className="col">
                        <h2 className="display-4 fw-bold text-x" data-aos="fade-up">Meet Our Team</h2>
                        <p className="lead" data-aos="fade-up" data-aos-delay="200">A dedicated team of professionals committed to our mission and vision.</p>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-3 g-4">
                    <div className="col" data-aos="zoom-in">
                        <div className="card h-100 shadow-lg">
                            <img src={avatar1} className="card-img-top" alt="Team Member" />
                            <div className="card-body text-center">
                                <h5 className="card-title fw-semibold fw-bold text-x">Joyce Masvaya</h5>
                                <p className="card-text fw-semibold">Co-founder and CEO</p>
                                <p className="card-text text-muted text-start">
                                    Joyce Masvaya is a serial entrepreneur passionate about event planning and management and she is currently based in the UK. She is a certified project manager and event planner. Well- travelled and experienced in collaborating with multi-cultural teams.
                                    Joyce is responsible for developing the vision, business strategies and plans that aligns with the objectives of Emoments. She sets the tone of Emoments’ culture, values and behaviors and oversees the day to day operations of the business to ensure customer satisfaction and profitability.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col" data-aos="zoom-in" data-aos-delay="200">
                        <div className="card h-100 shadow-lg">
                            <img src={CTO_Profile} className="card-img-top" alt="Team Member" />
                            <div className="card-body text-center">
                                <h5 className="card-title fw-bold text-x">Einstein Makuyana</h5>
                                <p className="card-text fw-semibold">Co-founder and CTO</p>
                                <p className="card-text text-muted text-start">
                                    Einstein Makuyana is a seasoned electronic and software engineering professional.
                                    Einstein is driven by a strong sense of purpose and a deep commitment to leveraging technology for the betterment of humanity. With a brilliant mind and a visionary perspective, Einstein leads the charge in harnessing cutting-edge innovations to create meaningful solutions that positively impact people's lives.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col" data-aos="zoom-in" data-aos-delay="400">
                        <div className="card h-100 shadow-lg">
                            <img src={CMO_Profile} className="card-img-top" alt="Team Member" />
                            <div className="card-body text-center">
                                <h5 className="card-title fw-bold text-x">Tonderai Kurewa</h5>
                                <p className="card-text fw-semibold">Chief Marketing Officer</p>
                                <p className="card-text text-muted text-start">
                                    Tonderai is a highly skilled business development strategist with a remarkable background in the fields of financial services and education. With over 12 years of experience in marketing, data analytics, strategy, and business development, coupled with his certification as a fintech practitioner, positions him as an asset for organizations seeking innovative solutions and sustainable growth.
                                    With his deep understanding of market dynamics and consumer behaviour, he formulates and executes data-driven marketing strategies that resonate with target audiences and elevate the Emoments brand.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    );
};

export default About;