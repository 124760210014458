import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlogsByCategory, setCurrentCategory, fetchCategories } from '../reducers/blogCategorySlice';
import { Link, useParams } from 'react-router-dom';
import { CircularProgress, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

const BlogCategory = () => {
    const dispatch = useDispatch();
    const { blogs, currentCategory, status, error, categories, categoriesStatus, categoriesError } = useSelector((state) => state.blogCategory);
    
    const { categoryName } = useParams();
    
    const capitalizeFirstLetter = (word) => {
        return word ? word.charAt(0).toUpperCase() + word.slice(1) : '';
    };

    useEffect(() => {
        if (categoryName) {
            const formattedCategory = capitalizeFirstLetter(categoryName);
            dispatch(setCurrentCategory(formattedCategory));
            dispatch(fetchBlogsByCategory(categoryName));
        }
    }, [categoryName, dispatch]);

    // Fetch categories on component mount
    useEffect(() => {
        if (categoriesStatus === 'idle') {
            dispatch(fetchCategories());
        }
    }, [categoriesStatus, dispatch]);

    const renderBlogs = () => {
        return blogs.map(blogPost => (
            <div key={blogPost.id} className="col-md-6 mb-4">
                <div className="card border-0 shadow-sm h-100">
                    <img src={blogPost.thumbnail} className="card-img-top" alt={blogPost.title} />
                    <div className="card-body d-flex flex-column">
                        <Typography variant="subtitle2" className='text-x'>
                            {capitalizeFirstLetter(blogPost.category)}
                        </Typography>
                        <Typography variant="h5" className="card-title fw-bold text-x mt-2">
                            {blogPost.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {blogPost.month} {blogPost.day}
                        </Typography>
                        <div className="mt-auto">
                            <Typography variant="body1" className="card-text text-secondary mb-3">
                                {blogPost.excerpt}
                            </Typography>
                            <Link to={`/blog/${blogPost.slug}`} className="btn btn-light text-x">
                                Continue Reading
                                <ArrowForward fontSize="small" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div className="container mt-4 mb-4">
            {/* Navigation for categories */}
            <nav className="nav d-flex flex-wrap justify-content-center mb-4 text-x">
                {categoriesStatus === 'loading' && <CircularProgress />}
                {categoriesStatus === 'succeeded' && categories.map(category => (
                    <Link key={category} className="nav-link" to={`/category/${category}`}>
                        {capitalizeFirstLetter(category)}
                    </Link>
                ))}
                {categoriesStatus === 'failed' && (
                    <Typography variant="body1" color="error">
                        Error: {categoriesError}
                    </Typography>
                )}
            </nav>

            {/* Header Section */}
            <div className="jumbotron p-4 p-md-5 text-white rounded bg-light mb-4">
                <div className="col-md-8 px-0">
                    <Typography variant="h3" className="display-4 font-italic text-x fw-bold">
                        {currentCategory || <CircularProgress color="inherit" />}
                    </Typography>
                </div>
            </div>

            {/* Blog List Section */}
            {status === 'loading' ? (
                <CircularProgress color="primary" />
            ) : (
                <div className="row">
                    {renderBlogs()}
                </div>
            )}

            {/* Error Handling */}
            {status === 'failed' && (
                <Typography variant="body1" color="error">
                    Error: {error}
                </Typography>
            )}
        </div>
    );
};

export default BlogCategory;
