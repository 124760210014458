import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchServices, fetchServiceCategories } from '../reducers/serviceSlice';

const ServiceCategoryFilter = () => {
    const dispatch = useDispatch();
    const { categories, status, error } = useSelector(state => state.serviceCategories);

    useEffect(() => {
        dispatch(fetchServiceCategories());
    }, [dispatch]);

    const handleCategoryChange = (categoryId) => {
        dispatch(fetchServices({ category: categoryId }));
    };

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="service-category-filter">
            <h3>Filter by Category</h3>
            <select onChange={(e) => handleCategoryChange(e.target.value)}>
                <option value="">All</option>
                {categories.map(category => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                ))}
            </select>
        </div>
    );
};

export default ServiceCategoryFilter;
