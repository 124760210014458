import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    blogs: [],
    featuredBlog: {},
    status: 'idle',
    error: null
};

// Thunks
export const fetchFeaturedBlog = createAsyncThunk(
    'blogs/fetchFeaturedBlog',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blog/featured`);
            // Check if response.data has the expected structure
            if (response.data && Array.isArray(response.data.results) && response.data.results.length > 0) {
                return response.data.results[0]; // Extract the featured blog
            } else {
                console.error('Expected an object with a results array but got:', response.data);
                return rejectWithValue('Unexpected data format for featured blog');
            }
        } catch (error) {
            console.error('Error fetching featured blog:', error);
            return rejectWithValue(error.message);
        }
    }
);

// Fetch all blogs with error handling and response validation
export const fetchBlogs = createAsyncThunk(
    'blogs/fetchBlogs',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blog/`);
            // Check if response.data has the expected structure
            if (response.data && Array.isArray(response.data.results)) {
                return response.data.results; // Extract the blogs array
            } else {
                console.error('Expected an object with a results array but got:', response.data);
                return rejectWithValue('Unexpected data format for blogs');
            }
        } catch (error) {
            console.error('Error fetching blogs:', error);
            return rejectWithValue(error.message);
        }
    }
);

// Slice
const blogSlice = createSlice({
    name: 'blogs',
    initialState,
    reducers: {
        // Define synchronous reducers if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFeaturedBlog.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchFeaturedBlog.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.featuredBlog = action.payload;
            })
            .addCase(fetchFeaturedBlog.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchBlogs.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBlogs.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.blogs = action.payload;
            })
            .addCase(fetchBlogs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default blogSlice.reducer;
