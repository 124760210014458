import React, { useState, useCallback } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { login } from '../actions/auth';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { Google, Facebook } from 'react-bootstrap-icons';
import logo2 from '../assets/Logo/logo2.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = ({ login, isAuthenticated }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { email, password } = formData;

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = useCallback(
        (e) => {
            e.preventDefault();
            setLoading(true);
            setError(null);
            login(email, password).catch((err) => {
                setError('Invalid credentials. Please try again.');
                toast.error('Invalid credentials. Please try again.');
                setLoading(false);
            });
        },
        [email, password, login]
    );

    const continueWithGoogle = useCallback(async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}/google`);
            window.location.replace(res.data.authorization_url);
            toast.success('Google authentication success')
        } catch (err) {
            setError('Google authentication failed.');
            toast.error('Google authentication failed.');
        }
    }, []);

    const continueWithFacebook = useCallback(async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/o/facebook/?redirect_uri=${process.env.REACT_APP_API_URL}/facebook`);
            window.location.replace(res.data.authorization_url);
            toast.success('Facebook authentication success')
        } catch (err) {
           setError('Facebook authentication failed.');
           toast.error('Facebook authentication failed.');
        }
    }, []);

    if (isAuthenticated) {
        return <Navigate to='/services' />;
    }

    return (
        <div className="d-flex align-items-center justify-content-center min-vh-100 bg-light p-5">
            <div className="card shadow-sm p-4" style={{ width: '100%', maxWidth: '400px' }}>
                <div className="text-center mb-4">
                    <img alt="Your Company" src={logo2} className="mb-3" style={{ height: '80px' }} />
                    <h2 className="h4 font-weight-bold">Sign in to your account</h2>
                </div>

                {error && <div className="alert alert-danger">{error}</div>}

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email address</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={handleInputChange}
                            required
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            value={password}
                            onChange={handleInputChange}
                            required
                            className="form-control"
                        />
                    </div>

                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <div className="form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="remember-me"
                                name="remember-me"
                            />
                            <label className="form-check-label" htmlFor="remember-me">Remember me</label>
                        </div>
                        <Link to="/reset-password" className="text-decoration-none text-primary">
                            Forgot password?
                        </Link>
                    </div>

                    <Button type="submit" className="w-100 btn btn-dark" disabled={loading}>
                        {loading ? 'Signing in...' : 'Sign In'}
                    </Button>
                </form>

                <div className="text-center my-3">
                    <span className="text-muted">Or continue with</span>
                </div>

                <div className="d-flex justify-content-between">
                    <Button
                        onClick={continueWithGoogle}
                        variant="outline-secondary"
                        className="w-100 me-2"
                        disabled={loading}
                    >
                        <Google className="me-2" /> Google
                    </Button>
                    <Button
                        onClick={continueWithFacebook}
                        variant="outline-secondary"
                        className="w-100"
                        disabled={loading}
                    >
                        <Facebook className="me-2" /> Facebook
                    </Button>
                </div>

                <p className="text-center mt-3">
                    Not a member?{' '}
                    <Link to="/signup" className="text-decoration-none text-primary">
                        Sign Up
                    </Link>
                </p>
            </div>
            <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} closeOnClick />
        </div>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { login })(Login);
