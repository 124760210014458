import React, { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout, facebookLogout, googleLogout } from '../actions/auth';
import logo4 from '../assets/Logo/logo4.png';
import { FaUser, FaSpinner } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/styles.css';

const Navbar = ({ isAuthenticated, facebookLogout, googleLogout, logout }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isBlurred, setIsBlurred] = useState(false);
    const [activeLink, setActiveLink] = useState(location.pathname);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsBlurred(window.scrollY > 50);
        };

        const updateActiveLink = () => {
            setActiveLink(location.pathname);
        };

        window.addEventListener('scroll', handleScroll);
        updateActiveLink();
        return () => window.removeEventListener('scroll', handleScroll);
    }, [location.pathname]);

    const handleLogout = useCallback(async () => {
        setIsLoading(true);
        try {
            await googleLogout();
            await facebookLogout();
            logout();
            toast.success('Successfully logged out!', { autoClose: 2000 });
            navigate('/');
        } catch (error) {
            console.error('Error during logout:', error);
            toast.error('Logout failed. Please try again.', { autoClose: 2000 });
        } finally {
            setIsLoading(false);
        }
    }, [googleLogout, facebookLogout, logout, navigate]);

    const guestLinks = useMemo(() => (
        <>
            <li className="nav-item ">
                <Link className="nav-link  btn btn-outline-secondary me-2" to="/login">Login</Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link btn btn-secondary" to="/signup">Sign Up</Link>
            </li>
        </>
    ), []);

    const authLinks = useMemo(() => (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle d-flex align-items-center" href="#!" id="accountDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <FaUser className="me-2" /> Account
            </a>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="accountDropdown">
                <li><Link className="dropdown-item" to="/profile">Profile</Link></li>
                <li><Link className="dropdown-item" to="/settings">Settings</Link></li>
                <li><hr className="dropdown-divider" /></li>
                <li>
                    <a className="dropdown-item btn btn-secondary" href="#!" onClick={handleLogout}>
                        {isLoading ? <FaSpinner className="me-2 spinner" /> : 'Logout'}
                    </a>
                </li>
            </ul>
        </li>
    ), [handleLogout, isLoading]);

    return (
        <Fragment>
            <nav className={`navbar sticky-top navbar-expand-lg navbar-light bg-light ${isBlurred ? 'navbar-blurred' : ''}`}>
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">
                        <img className="logo" src={logo4} alt="Brand Logo" />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <ul className="navbar-nav collapse navbar-collapse nav col-12 col-md-auto mb-2 justify-content-center mb-md-0" id="navbarNav">
                        <li className={`nav-item ${activeLink === '/' ? 'active' : ''}`}>
                          <Link className="nav-link" to="/" aria-current={activeLink === '/' ? 'page' : undefined}>Home</Link>
                        </li>
                        <li className={`nav-item ${activeLink === '/about' ? 'active' : ''}`}>
                          <Link className="nav-link" to="/about" aria-current={activeLink === '/about' ? 'page' : undefined}>About Us</Link>
                        </li>
                        <li className={`nav-item ${activeLink === '/blog' ? 'active' : ''}`}>
                          <Link className="nav-link" to="/blog" aria-current={activeLink === '/blog' ? 'page' : undefined}>Blog</Link>
                        </li>
                        <li className={`nav-item ${activeLink === '/services' ? 'active' : ''}`}>
                          <Link className="nav-link" to="/services" aria-current={activeLink === '/services' ? 'page' : undefined}>Services</Link>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link" href="https://www.facebook.com/BlitzTechElectronics/" target="_blank" rel="noopener noreferrer">Community</a>
                        </li>
                    </ul>
                    <div className='col-md-3  collapse navbar-collapse text-end'>                        
                      <ul className="navbar-nav ms-auto">
                        {isAuthenticated ? authLinks : guestLinks}
                      </ul>
                    </div>
                </div>
            </nav>
            <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} closeOnClick />
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { logout, facebookLogout, googleLogout })(Navbar);
