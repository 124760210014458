import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { fetchServiceById, fetchServices, resetError } from '../reducers/serviceSlice';
import {  fetchBusinessAdmins, fetchPaymentStatus, fetchBookingStatus} from '../reducers/bookingSlice';
import { ArrowForward } from '@mui/icons-material';
import Spinner from './Spinner';
import BookingForm from './BookingForm';
import { Modal } from 'react-bootstrap'; // Bootstrap modal for booking

const ServiceDetail = () => {
  const { id } = useParams(); // Get service ID from URL params
  const dispatch = useDispatch();

  // State to handle modal visibility
  const [showModal, setShowModal] = useState(false);

  // Extracting data and status from Redux store
  const {
    serviceDetail,
    services,
    status,
    error
  } = useSelector((state) => state.services);
  const { businessAdmins } = useSelector((state) => state.booking || {});
  const { bookingStatus } = useSelector((state) => state.booking || {});
  const { paymentStatus } = useSelector((state) => state.booking || {});

  // Modal handlers
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // Fetch service details and related services when component mounts
  useEffect(() => {
    dispatch(fetchServiceById(id));
    dispatch(fetchServices()); // Fetch all services to find related ones
    // Load business admins on component mount 
    dispatch(fetchBusinessAdmins());
    //Load payment status
    dispatch(fetchPaymentStatus());
    // Load booking status
    dispatch(fetchBookingStatus());
  

    // Reset errors on component unmount
    return () => {
      dispatch(resetError());
    };
  }, [dispatch, id]);

  // Utility to capitalize the first letter of a string
  const capitalizeFirstLetter = (word) => word ? word.charAt(0).toUpperCase() + word.slice(1) : '';

  // Filter related services, excluding the currently viewed service
  const relatedServices = useMemo(() => {
    return services.filter(service => 
      service.category === serviceDetail?.category && service.id !== serviceDetail?.id
    );
  }, [services, serviceDetail]);

  // Display loading state
  if (status === 'loading') {
    return <Spinner />;
  }

  // Display error state
  if (error) {
    return <div className="alert alert-danger">Error: {error}</div>;
  }

  // Display fallback if no service details are found
  if (!serviceDetail) {
    return <p>No service details found.</p>;
  }

  return (
    <main className="service-detail-page py-5">
      <div className="container-fluid px-4">
        <div className="row">
          {/* Left side - Main service image */}
          <div className="col-lg-6 mb-4">
            <div className="service-image position-relative">
              <div className="behind-img position-absolute rounded-5" style={{ backgroundColor: '#f3c96d' }}></div>
              <img
                src={serviceDetail.photo}
                alt={serviceDetail.title}
                className="img-fluid rounded-5 position-relative z-1"
                style={{ maxHeight: '400px', width: '100%', objectFit: 'cover' }}
              />
            </div>
          </div>

          {/* Right side - Service details */}
          <div className="col-lg-6">
            <div className="service-info card border-0 p-4 shadow-sm">
              <div className="service-title mb-4">
                <h3 className="fw-bold text-x">{capitalizeFirstLetter(serviceDetail.title)}</h3>
              </div>
              <div className="service-description mb-4">
                <p className="text-secondary">{serviceDetail.vendor?.description || 'No vendor description available'}</p>
              </div>
              <div className="service-price mb-4">
                <h5 className="text-x fw-bold">Price: ${serviceDetail.price}</h5>
              </div>
              <div className="vendor-info mb-4 text-secondary">
                <p><strong>Vendor:</strong> {serviceDetail.vendor?.name} ({serviceDetail.vendor?.company})</p>
              </div>  
              <div className="service-category mb-4">
                <h5 className="text-x fw-bold">{capitalizeFirstLetter(serviceDetail.category)}</h5>
              </div>
              <div className="service-description mb-4">
                <p className="text-secondary">{serviceDetail.description || 'No description available'}</p>
              </div>
              <button className="btn btn-light text-x" onClick={handleShowModal}>
                Book Now 
                <ArrowForward fontSize="small" />
              </button>
            </div>
          </div>
        </div>

        {/* Related services section */}
        <div className="related-services mt-5">
          <h4 className="mb-4 fw-bold text-x">Related Services</h4>
          <div className="row">
            {relatedServices.length > 0 ? (
              relatedServices.map((relatedService) => (
                <div className="col-lg-4 col-md-6 py-2" key={relatedService.id}>
                  <div className="card border-0 shadow-sm">
                    <div className="card-img-top">
                      <img
                        src={relatedService.photo}
                        alt={relatedService.title}
                        className="img-fluid rounded-2"
                        style={{ maxHeight: '200px', objectFit: 'cover' }}
                      />
                    </div>
                    <div className="card-body text-center">
                      <h5 className="card-title text-x fw-bold">{capitalizeFirstLetter(relatedService.title)}</h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        ${relatedService.price}
                      </h6>
                      <p className="card-text">
                        {relatedService.vendor?.name} ({relatedService.vendor?.company})
                      </p>
                      <Link to={`/services/${relatedService.id}`} className="btn btn-light text-x">
                        View Details <ArrowForward fontSize="small" />
                      </Link>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No related services found.</p>
            )}
          </div>
        </div>
      </div>

      {/* Booking Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Booking {serviceDetail.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Pass serviceId to the BookingForm */}
          <BookingForm 
            serviceId={serviceDetail.id}
            serviceName={serviceDetail.title}
            admin={businessAdmins?.id || 1} 
            paymentStatus={paymentStatus?.name || 1} 
            bookingStatus={bookingStatus?.name || 1} />
        </Modal.Body>
      </Modal>
    </main>
  );
};

export default ServiceDetail;
