import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchBlogs, fetchFeaturedBlog } from '../reducers/blogSlice';
import { CircularProgress, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';

const Blog = () => {
    const dispatch = useDispatch();
    const { blogs, featuredBlog, status, error } = useSelector((state) => state.blogs);

    useEffect(() => {
        dispatch(fetchFeaturedBlog());
        dispatch(fetchBlogs());
    }, [dispatch]);

    const capitalizeFirstLetter = (word) => {
        if (word) return word.charAt(0).toUpperCase() + word.slice(1);
        return '';
    };

    const getBlogs = () => {
        const list = blogs.map(blogPost => (
            <div key={blogPost.id} className="col-md-6 mb-4">
                <div className="card border-0 shadow-sm h-100">
                    <img src={blogPost.thumbnail} className="card-img-top" alt={blogPost.title} />
                    <div className="card-body d-flex flex-column">
                        <Typography variant="subtitle2" className='text-x'>
                            {capitalizeFirstLetter(blogPost.category)}
                        </Typography>
                        <Typography variant="h5" className="card-title fw-bold text-x mt-2">
                            {blogPost.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                            {blogPost.month} {blogPost.day}
                        </Typography>
                        <div className="mt-auto">
                            <Typography variant="body1" className="card-text text-secondary mb-3">
                                {blogPost.excerpt}
                            </Typography>
                            <Link to={`/blog/${blogPost.slug}`} className="btn btn-light text-x">
                                Continue Reading
                                <ArrowForward fontSize="small" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        ));

        return (
            <div className="row">
                {list}
            </div>
        );
    };

    return (
        <div className='container mt-4'>
            <nav className="nav d-flex flex-wrap justify-content-center mb-4">
                {['world', 'environment', 'technology', 'design', 'culture', 'business'].map(category => (
                    <Link key={category} className="nav-link" to={`/category/${category}`}>
                        {capitalizeFirstLetter(category)}
                    </Link>
                ))}
            </nav>

            <div className="jumbotron p-4 p-md-5 rounded bg-light mb-4">
                <div className="col-md-8 px-0">
                    <Typography variant="h3" className="display-4 font-italic fw-bold text-x">
                        {featuredBlog.title || <CircularProgress color="inherit" />}
                    </Typography>
                    <Typography variant="h5" className="my-3 text-secondary">
                        {featuredBlog.excerpt || <CircularProgress color="inherit" />}
                    </Typography>
                    {featuredBlog.slug && (
                        <Link to={`/blog/${featuredBlog.slug}`} className="btn btn-light text-x fw-bold btn-lg">
                            Continue reading
                            <ArrowForward fontSize="small" />
                        </Link>
                    )}
                </div>
            </div>

            {status === 'loading' ? <CircularProgress color="primary" /> : getBlogs()}
            {status === 'failed' && <Typography variant="body1" color="error">Error: {error}</Typography>}
        </div>
    );
};

export default Blog;
