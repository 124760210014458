import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchBlogBySlug } from '../reducers/blogDetailSlice'; // Adjust the path as necessary

const BlogDetail = () => {
    const { slug } = useParams();
    const dispatch = useDispatch();

    // Safely extract blog, status, and error from the state
    const { blog, status, error } = useSelector((state) => state.blogDetail || {});

    useEffect(() => {
        if (slug) {
            dispatch(fetchBlogBySlug(slug));
        }
    }, [dispatch, slug]);

    const createBlogContent = () => {
        return { __html: blog ? blog.content : '' }; // Safely prevent undefined content
    };

    const capitalizeFirstLetter = (word) => {
        if (word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return '';
    };

    // Handle loading state
    if (status === 'loading') {
        return <div className="text-center py-5"><div className="spinner-border text-primary" role="status"></div> Loading...</div>;
    }

    // Handle failed state
    if (status === 'failed') {
        return <div className="alert alert-danger">Error: {error}</div>;
    }

    // Handle case where no blog is found or available
    if (!blog) {
        return <p className="text-center text-muted">No blog data available</p>;
    }

    return (
        <div className='container mt-5'>
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <h1 className='display-3 mb-4 text-center text-x fw-bold'>{blog.title}</h1>
                    <h5 className='text-secondary text-center'>
                        <span className='badge bg-light text-x'>
                            {capitalizeFirstLetter(blog.category)}
                        </span>
                    </h5>
                    <p className='text-center text-muted mb-4'>
                        {blog.month} {blog.day}
                    </p>
                    <div className='content-body mt-4 mb-5 text- fw-semibold' dangerouslySetInnerHTML={createBlogContent()} />
                    <hr />
                    <div className="d-flex justify-content-center mb-4">
                        <Link to='/blog' className='btn btn-outline-secodary text-x d-flex align-items-center'>
                            <ArrowBackIcon className="me-2" /> Back to Blogs
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDetail;
