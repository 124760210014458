import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './containers/Home';
import Login from './containers/Login';
import Activate from './containers/Activate';
import Signup from './containers/Signup';
import ResetPassword from './containers/ResetPassword';
import ResetPasswordConfirm from './containers/ResetPasswordConfirm';
import Facebook from './containers/Facebook'
import Google from './containers/Google'
import './styles/styles.css';
import { Provider } from 'react-redux';
import store from './store';
import Layout from './hocs/Layout';
import Blog from './components/Blog';
import Testimonies from './containers/Testimonials';
import Contact from './containers/ContactUs';
import About from './containers/About';
import BlogDetail from './components/BlogDetail';
import BlogCategory from './components/BlogCategory';
import Service from './components/ServiceList';
import ServiceDetail from './components/ServiceDetail';
import ServiceCategoryFilter from './components/ServiceCategoryFilter';
import FAQ from './containers/FAQ';
import BookingForm from './components/BookingForm';

const App = () => (
    <Provider store={store}>
        <Router>
            <Layout>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route exact path='/blog' element={<Blog/>} />
                    <Route exact path='/category/:categoryName' element={<BlogCategory/>} />
                    <Route exact path='/blog/:slug' element={<BlogDetail/>} />
                    <Route exact path='/testimonies' element={<Testimonies/>} />
                    <Route exact path='/faq' element={<FAQ/>} />
                    <Route exact path='/about' element={<About/>} />
                    <Route exact path='/contact' element={<Contact/>} />
                    <Route exact path='/login' element={<Login />} />
                    <Route exact path='/signup' element={<Signup />} />
                    <Route exact path='/facebook' element={<Facebook />} />
                    <Route exact path='/google' element={<Google />} />
                    <Route exact path='/reset-password' element={<ResetPassword />} />
                    <Route exact path='/password/reset/confirm/:uid/:token' element={<ResetPasswordConfirm />} />
                    <Route exact path='/activate/:uid/:token' element={<Activate />} />
                    <Route path="/services" element={<Service />} />
                    <Route path="/services/:id" element={<ServiceDetail />} />
                    <Route path="/services/category" element={<ServiceCategoryFilter/>} />
                    <Route path="/services/bookings" element={<BookingForm/>}/>
                </Routes>
            </Layout>
        </Router>
    </Provider>
);

export default App;
