import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Initial state
const initialState = {
    blogs: [],
    currentCategory: '',
    categories: [], // To store categories fetched from backend
    status: 'idle',
    categoriesStatus: 'idle', // Status for categories loading
    error: null,
    categoriesError: null
};

// Thunks

// Fetch blogs by category
export const fetchBlogsByCategory = createAsyncThunk(
    'blogs/fetchBlogsByCategory',
    async (category, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/blog/category`, { category });
            if (response.data && Array.isArray(response.data)) {
                return response.data; // Extract the blogs array
            } else {
                return rejectWithValue('Unexpected data format for blogs');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Fetch all categories from backend
export const fetchCategories = createAsyncThunk(
    'categories/fetchCategories',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blog/categories`);
            if (response.data && Array.isArray(response.data)) {
                return response.data; // Assuming it's an array of category names
            } else {
                return rejectWithValue('Unexpected data format for categories');
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Slice
const blogCategorySlice = createSlice({
    name: 'blogCategory',
    initialState,
    reducers: {
        setCurrentCategory(state, action) {
            state.currentCategory = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            // Handling fetchBlogsByCategory
            .addCase(fetchBlogsByCategory.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchBlogsByCategory.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.blogs = action.payload;
            })
            .addCase(fetchBlogsByCategory.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // Handling fetchCategories
            .addCase(fetchCategories.pending, (state) => {
                state.categoriesStatus = 'loading';
            })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.categoriesStatus = 'succeeded';
                state.categories = action.payload;
            })
            .addCase(fetchCategories.rejected, (state, action) => {
                state.categoriesStatus = 'failed';
                state.categoriesError = action.payload;
            });
    }
});

export const { setCurrentCategory } = blogCategorySlice.actions;

export default blogCategorySlice.reducer;
