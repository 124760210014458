import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import avatar1 from '../assets/Avatar/avatar1.jpg'
import avatar2 from '../assets/Avatar/avatar2.jpg'
import avatar4 from '../assets/Avatar/avatar4.jpg'

function TestimonyCarousel() {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [testimonies, setTestimonies] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const fetchTestimonies = async () => {
            try {
                // Simulated API response
                const response = [
                    {
                        name: 'Emily Williams',
                        text: 'Emoments made our corporate event a huge success. Seamless coordination and excellent vendor services.',
                        image: avatar1,
                        position: 'Event Manager, TechCorp'
                    },
                    {
                        name: 'Michael Brown',
                        text: 'A fantastic platform for finding reliable event vendors. We saved so much time and effort thanks to Emoments.',
                        image: avatar2,
                        position: 'CEO, BizEvents'
                    },
                    {
                        name: 'Sarah Johnson',
                        text: 'Great experience using Emoments for our wedding planning. Everything was so well-organized, and the vendors were top-notch!',
                        image: avatar4,
                        position: 'Bride'
                    }
                ];

                // Simulated delay for loading
                setTestimonies(response);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchTestimonies();
    }, []);

    const handleSelect = (selectedIndex, e) => {
        setActiveIndex(selectedIndex);
    };

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="alert alert-danger text-center" role="alert">
                Error: {error.message}
            </div>
        );
    }

    return (
        <div className="testimonies-page py-5">
            <div className="row text-center">
                <div className="col">
                    <h2 className="testimonies-title text-x fw-bold">What Our Clients Say</h2>
                    <p className="lead testimonies-subtitle fw-semibold text-secondary">Hear from satisfied event planners and service providers.</p>
                </div>
            </div>
            <Carousel activeIndex={activeIndex} onSelect={handleSelect} className='container'>
                {testimonies.map((testimony, index) => (
                    <Carousel.Item key={index}>
                        <div id="test-content" className="d-flex flex-column align-items-center text-center bg-light border pt-5 rounded shadow-sm">
                            <img
                                src={testimony.image}
                                className="rounded-circle testimony-image mb-3"
                                alt={testimony.name}
                                style={{ width: '150px', height: '150px' }}
                            />
                            <h3 className="fw-bold text-x">{testimony.name}</h3>
                            <h5 className="text-muted">{testimony.position}</h5>
                            <p className="testimony-text text-secondary mt-3">
                                "{testimony.text}"
                            </p>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
}

export default TestimonyCarousel;
