import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBooking, resetError } from '../reducers/bookingSlice';
import { useNavigate } from 'react-router-dom';

const BookingForm = ({ serviceId, serviceName, admin, paymentStatus, bookingStatus }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Select necessary data from Redux store
  const { status, error } = useSelector((state) => state.bookings || {});

  // Local state for form inputs
  const [formData, setFormData] = useState({
    service: serviceId,
    service_date: '',
    customer_name: '',
    customer_email: '',
    customer_phone: '',
    notes: '',
    cancellation_policy_acknowledged: false,
    service_duration: '',
    booking_status: bookingStatus,
    payment_status: paymentStatus,
    business_admin: admin,
  });

  const [loading, setLoading] = useState(false);

  // Update form data when props change
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      service: serviceId,
      booking_status: bookingStatus,
      payment_status: paymentStatus,
      business_admin: admin,
    }));
  }, [serviceId, admin, paymentStatus, bookingStatus]);

  // Clear error when component is unmounted
  useEffect(() => {
    return () => {
      dispatch(resetError());
    };
  }, [dispatch]);

  // Handle form changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(createBooking(formData)).unwrap();
      navigate('/services');
    } catch (err) {
      console.error('Failed to create booking:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      <h2>Create a Booking</h2>
      {status === 'failed' && <div className="alert alert-danger">{error}</div>}
      <form onSubmit={handleSubmit}>
        {/* Service ID (display-only) */}
        <div className="form-group">
          <input
            type="hidden"
            name="service"
            value={formData.service}
            className="form-control"
            readOnly
          />
        </div>

        {/* Service Date */}
        <div className="form-group">
          <label htmlFor="service_date">Service Date</label>
          <input
            type="datetime-local"
            id="service_date"
            name="service_date"
            className="form-control"
            value={formData.service_date}
            onChange={handleChange}
            required
          />
        </div>

        {/* Customer Name */}
        <div className="form-group">
          <label htmlFor="service_date">Customer Name</label>
          <input
            type="text"
            id="customer_name"
            name="customer_name"
            className="form-control"
            value={formData.customer_name}
            onChange={handleChange}
            required
          />
        </div>

        {/* Customer Phone */}
        <div className="form-group">
          <label htmlFor="service_date">Customer Phone</label>
          <input
            type="interger"
            id="customer_phone"
            name="customer_phone"
            className="form-control"
            value={formData.customer_phone}
            onChange={handleChange}
            required
          />
        </div>

        {/* Customer Email */}
        <div className="form-group">
          <label htmlFor="service_date">Customer Email</label>
          <input
            type="email"
            id="customer_email"
            name="customer_email"
            className="form-control"
            value={formData.customer_email}
            onChange={handleChange}
            required
          />
        </div>

        {/* Notes*/}
        <div className="form-group">
          <label htmlFor="service_date">Notes</label>
          <input
            type="text"
            id="notes"
            name="notes"
            className="form-control"
            value={formData.notes}
            onChange={handleChange}
            required
          />
        </div>

         {/* Service Duration */}
         <div className="form-group">
          <label htmlFor="service_duration">Service Duration (in hours)</label>
          <input
            type="number"
            id="service_duration"
            name="service_duration"
            className="form-control"
            value={formData.service_duration}
            onChange={handleChange}
            required
          />
        </div>

        {/* Cancellation Policy Acknowledgement */}
        <div className="form-check">
          <input
            type="checkbox"
            id="cancellation_policy_acknowledged"
            name="cancellation_policy_acknowledged"
            className="form-check-input"
            checked={formData.cancellation_policy_acknowledged}
            onChange={handleChange}
            required
          />
          <label className="form-check-label" htmlFor="cancellation_policy_acknowledged">
            I acknowledge the cancellation policy
          </label>
        </div>
        
        {/* Booking Status */}
        <input type="hidden" name="booking_status" value={formData.booking_status} />
        
        {/* Payment Status */}
        <input type="hidden" name="payment_status" value={formData.payment_status} />

        {/* Business Admin */}
        <div className="form-group">
          <input
            type="hidden"
            name="business_admin"
            value={formData.business_admin}
            className="form-control"
            readOnly
          />
        </div>

        {/* Submit Button */}
        <button type="submit" className="btn btn-primary mt-3" disabled={loading}>
          {loading ? 'Booking...' : 'Book Now'}
        </button>
      </form>
    </div>
  );
};

export default BookingForm;
