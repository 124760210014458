import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import ChatIcon from '@mui/icons-material/Chat';
import InsightsIcon from '@mui/icons-material/Insights';

const features = [
  {
    name: 'Event Planning and Coordination',
    description:
      'Emoments provides tools for planning and coordinating events of all sizes. Service providers can showcase their offerings and manage bookings, contracts, and payments seamlessly.',
    icon: EventIcon,
  },
  {
    name: 'Vendor Network',
    description:
      'We have curated a trusted network of service providers across various event-related industries, giving them exposure to potential clients and opportunities.',
    icon: PeopleIcon,
  },
  {
    name: 'Communication and Collaboration Tools',
    description:
      'Seamless communication between event organizers and service providers with tools for discussion, document sharing, and collaboration, ensuring event success.',
    icon: ChatIcon,
  },
  {
    name: 'Analytics and Insights',
    description:
      'Advanced analytics to track performance, customer feedback, and data-driven insights to optimize offerings and enhance service providers’ reputation.',
    icon: InsightsIcon,
  },
];

const FeaturesSection = () => {
  return (
    <div className="py-5">
      <div className="container">
        <div className="text-center mb-5">
          <p className="h1 fw-bold text-x">
            Key Features of Our Platform
          </p>
          <p className="lead fw-semibold text-secondary">
            Our platform offers a comprehensive solution for event planners and service providers to collaborate efficiently, manage bookings, and grow their businesses.
          </p>
        </div>
        <div className="row">
          {features.map((feature) => (
            <div key={feature.name} className="col-md-6 mb-4">
              <div className="d-flex align-items-start">
                <div className="bg-x text-white p-3 rounded-circle me-3">
                  <feature.icon aria-hidden="true" className="h-6 w-6" />
                </div>
                <div>
                  <h4 className="mb-2 fw-bold text-x">{feature.name}</h4>
                  <p className="mb-0 fw-light text-secondary">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center mt-4">
          <a href="/event-planning-consumer" className="btn px-4 rounded-pill btn-light fw-bold text-x mx-2">Start Planning</a>
          <a href="/supplier" className="btn btn-outline-secondary px-4 rounded-pill fw-bold text-x mx-2">Become a Supplier</a>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;
