import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


// Thunks for CRUD operations
export const fetchCategories = createAsyncThunk(
  'categories/fetchCategories',
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/categories/`);
    return response.data;
  }
);

export const addCategory = createAsyncThunk(
  'categories/addCategory',
  async (newCategory) => {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/categories/`, newCategory);
    return response.data;
  }
);

export const updateCategory = createAsyncThunk(
  'categories/updateCategory',
  async (updatedCategory) => {
    const { id, ...data } = updatedCategory;
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/categories/{id}/`, data);
    return response.data;
  }
);

export const deleteCategory = createAsyncThunk(
  'categories/deleteCategory',
  async (id) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/api/categories/{id}/`);
    return id;
  }
);

// Initial state
const initialState = {
  categories: [],
  activeCategory: null,
  status: 'idle',
  error: null,
};

// Category slice
const categorySlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setActiveCategory: (state, action) => {
      state.activeCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.categories = action.payload;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        state.categories.push(action.payload);
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        const index = state.categories.findIndex(cat => cat.id === action.payload.id);
        if (index !== -1) {
          state.categories[index] = action.payload;
        }
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.categories = state.categories.filter(cat => cat.id !== action.payload);
      });
  },
});

// Export actions and reducer
export const { setActiveCategory } = categorySlice.actions;
export default categorySlice.reducer;
