import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { submitContactForm } from '../actions/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TextField, Button, Typography, Box } from '@mui/material';
import { Email as EmailIcon, Person as PersonIcon, Message as MessageIcon } from '@mui/icons-material';

const ContactForm = () => {
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFormData((prevData) => ({ ...prevData, [id]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setValidated(true);
        const { name, email, message } = formData;
        dispatch(submitContactForm(name, email, message));
    };

    return (
        <Box className="bg-light py-5">
            <Box className="container">
                <Box className="row text-center mb-5">
                    <Box className="col">
                        <Typography variant="h2" className="contact-title text-x fw-bold">Contact Us</Typography>
                        <Typography variant="subtitle1" className="text-muted fw-semibold">Get in touch with us for any queries or feedback.</Typography>
                    </Box>
                </Box>
                <Box className="row">
                    <Box id='test-content' className="col-md-6 container">
                        <form noValidate validated={validated.toString()} onSubmit={handleSubmit} className="shadow-lg p-4 rounded bg-white">
                            <Box className="mb-3">
                                <TextField
                                    id="name"
                                    label="Name"
                                    placeholder="Enter your name"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                    InputProps={{
                                        startAdornment: <PersonIcon position="start" />
                                    }}
                                />
                                <div className="invalid-feedback">
                                    Please provide your name.
                                </div>
                            </Box>
                            <Box className="mb-3">
                                <TextField
                                    id="email"
                                    label="Email"
                                    placeholder="Enter your email"
                                    variant="outlined"
                                    fullWidth
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                    InputProps={{
                                        startAdornment: <EmailIcon position="start" />
                                    }}
                                />
                                <div className="invalid-feedback">
                                    Please provide a valid email.
                                </div>
                            </Box>
                            <Box className="mb-3">
                                <TextField
                                    id="message"
                                    label="Message"
                                    placeholder="Enter your message"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={4}
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    required
                                    InputProps={{
                                        startAdornment: <MessageIcon position="start" />
                                    }}
                                />
                                <div className="invalid-feedback">
                                    Please enter a message.
                                </div>
                            </Box>
                            <Button type="submit" variant="contained" color="secondary" fullWidth className="mt-3 text-x">
                                Submit
                            </Button>
                        </form>
                    </Box>
                    <Box id='test-content' className="col-md-6 mb-5 container">
                        <Box className="map-responsive shadow-lg rounded">
                            <iframe
                                title="Google Maps"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434508577!2d144.9537353153166!3d-37.81627974202198!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf5775c8f4a3c4c0a!2sVictoria%20Market!5e0!3m2!1sen!2sau!4v1616533916027!5m2!1sen!2sau"
                                width="100%"
                                height="450"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default ContactForm;
