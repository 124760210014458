import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Features from './Features';
import WhyUs from './whyUs';
import Testimonials from './Testimonials';
import FAQ from './FAQ';
import Contact from './ContactUs';
import BlogCTA from '../components/BlogCTA';
import cover1 from '../assets/Cover/cover1.jpg';
import cover3 from '../assets/Cover/cover3.jpg';
import cover4 from '../assets/Cover/cover4.jpg';

const Home = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div className="main container-fluid p-0">
      {/* Carousel Section */}
      <div id="myCarousel" className="carousel slide" data-bs-ride="carousel" style={{ height: '80vh', margin: '0' }}>
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="0"
            className="active"
            aria-label="Slide 1"
            aria-current="true"
          ></button>
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#myCarousel"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div className="carousel-inner h-100">
          <div className="carousel-item active h-100" data-aos="fade-up">
            <img
              src={cover1}
              className="d-block w-100 h-100"
              alt="Slide 1"
            />
            <div className="carousel-caption d-none d-md-block text-start">
              <h1 className="display-4">Example headline.</h1>
              <p className="lead">Some representative placeholder content for the first slide of the carousel.</p>
              <p>
                <a className="btn btn-lg btn-light" href="#">
                  Sign up today
                </a>
              </p>
            </div>
          </div>
          <div className="carousel-item h-100" data-aos="fade-up">
            <img
              src={cover4}
              className="d-block w-100 h-100"
              alt="Slide 2"
            />
            <div className="carousel-caption d-none d-md-block">
              <h1 className="display-4">Another example headline.</h1>
              <p className="lead">Some representative placeholder content for the second slide of the carousel.</p>
              <p>
                <a className="btn btn-lg btn-light" href="#">
                  Learn more
                </a>
              </p>
            </div>
          </div>
          <div className="carousel-item h-100" data-aos="fade-up">
            <img
              src={cover3}
              className="d-block w-100 h-100"
              alt="Slide 3"
            />
            <div className="carousel-caption d-none d-md-block text-end">
              <h1 className="display-4">One more for good measure.</h1>
              <p className="lead">Some representative placeholder content for the third slide of this carousel.</p>
              <p>
                <a className="btn btn-lg btn-light" href="#">
                  Browse gallery
                </a>
              </p>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#myCarousel"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* Why Us Section */}
      <WhyUs/>

      {/* Features Section */}
      <Features/>
      {/*Blog CTA Section*/}
      <BlogCTA/>      
      {/*FAQ*/}
      <FAQ/>
      {/* Testimonials Section */}
      <Testimonials />
      {/* Contact Section */}
      <Contact />
    </div>
  );
};

export default Home;
