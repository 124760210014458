import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subscribe } from '../actions/auth'; 
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';
import logo from '../assets/Logo/logo5.png'

const TermsAndConditions = () => {
  const [termsChecked, setTermsChecked] = useState(false);
  const [showPanel, setShowPanel] = useState(false);
  const handleShowTerms = (event) => {
    setShowPanel(true);
  };

  const handleAcceptTerms = (event) => {
    setTermsChecked(true);
    setShowPanel(false);
  };

  const handleDeclineTerms = () => {
    setShowPanel(false);
    setTermsChecked(false)
  };

  return (
    <div className="terms-conditions p-3">
      <div className="form-check mb-3">
        <input 
          type="checkbox"
          className="form-check-input"
          id="termsCheckbox"
          checked={termsChecked}
          onChange={handleShowTerms}
          style={{border: '2px solid black'}}
        />
        <label className="form-check-label " htmlFor="termsCheckbox" style={{marginLeft: 0}}>
          I agree to the terms and conditions
        </label>
      </div>
      <button
        type="submit"
        className="btn btn-outline-primary"
        disabled={!termsChecked}
      >
        Subscribe
      </button>

      {showPanel && (
        <div className="modal fade show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Terms and Conditions</h5>
              </div>
              <div className="modal-body">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  nec purus feugiat, molestie ipsum et, consequat nibh. Etiam non
                  erat vel est tempus volutpat. Nam eget lorem ac dolor
                  facilisis ultricies.
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleAcceptTerms}
                >
                  Accept
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={handleDeclineTerms}
                >
                  Decline
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
const Footer = () => {
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const { isSubscribed = false, error } = useSelector(state => state.subscription || {});

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(subscribe(email));
  };
  return (
    <footer className="footer mt-auto py-5 shadow-lg" style={{ backgroundColor: '#EAEAEA', paddingBottom: 0}}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-3 mb-3 mb-md-0">
            <div id="footer-about-us" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
              <h4 className='text-x fw-bold'>About Us</h4>
              <p style={{fontSize:'18px', alignContent: 'center'}}>
                We are a leading multivendor platform, 
                connecting buyers and sellers worldwide. 
                Our mission is to provide the best marketplace 
                experience.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-2 mb-3 mb-md-0">
            <div id="footer-quick-links" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
              <h4 className='text-x fw-bold'>Quick Links</h4>
              <ul className="list-unstyled">
                <li><a href="/">Home</a></li>
                <li><a href="/">Shop</a></li>
                <li><a href="/about">About</a></li>
                <li><a href="/contact">Contact</a></li>
                <li><a href="/faq">FAQ</a></li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <div id="footer-follow-us" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            <h4 className='text-x fw-bold'>Follow Us</h4>
                <ul id="social-icons" className="list-unstyled">
                  <li>
                      <a href="https://www.facebook.com/profile.php?id=61558092445373" target="new">
                          <FacebookIcon/>
                      </a>
                  </li>
                  <li>
                      <a href="#">
                          <InstagramIcon/>
                      </a>
                  </li>
                  <li>
                      <a href="#">
                          <XIcon/>
                      </a>
                  </li>
                  <li>
                      <a href="https://www.linkedin.com/company/emoments/?viewAsMember=true" target="new">
                          <LinkedInIcon/>
                      </a>
                  </li>
                  <li>
                      <a href="https://www.tiktok.com/@emoments1?_t=8mhdheWue7p&_r=1" target="new">
                          <YouTubeIcon/>
                      </a>
                  </li>

                </ul>
            </div>
          </div>
          <div className="col-12 col-md-3 mb-3 mb-md-0">
            <div id="footer-subscribe-div" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
              <h4 className='text-x fw-bold'>Subscribe to our Newsletter</h4>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Email address</label>
                  <input
                    id="email"
                    type="email"
                    className="form-control"
                    placeholder="Your email address"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <TermsAndConditions />
                {isSubscribed && <p className="text-success mt-2">Subscribed successfully!</p>}
                {error && <p className="text-danger mt-2">{error}</p>}
              </form>
            </div>
          </div>
        </div>
        <hr />
        <div id="footer-bottom" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div id="payment-methods" style={{ flex: 1 }}>              
            <div className="text-center" style={{ flex: 1, textAlign: 'center' }}>
              <p>&copy; {new Date().getFullYear()} <img className=' mx-2' style={{ width: '150px', height: '30px' }} src={logo} alt='logo'/>.  <strong>All rights reserved.</strong></p>
            </div>
            </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
