import { combineReducers } from "redux";
import auth from "./auth";
import categoryReducer from "./categorySlice";
import blogReducer from "./blogSlice";
import blogDetailReducer from "./blogDetailSlice"
import blogCategoryReducer from "./blogCategorySlice"
import serviceReducer from "./serviceSlice"
import bookingReducer from "./bookingSlice"

export default combineReducers({
    auth,
    categories: categoryReducer,
    blogs: blogReducer,
    blogDetail: blogDetailReducer,
    blogCategory : blogCategoryReducer,
    services: serviceReducer,
    booking: bookingReducer,
});
