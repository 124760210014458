import React from 'react';
import '../styles/styles.css'

const FAQ = () => {
    const faqs = [
        {
            id: "1",
            question: "How do you make holy water?",
            answer: "You boil the hell out of it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
            isOpen: true,
        },
        {
            id: "2",
            question: "What's the best thing about Switzerland?",
            answer: "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
            isOpen: false,
        },
        {
            id: "3",
            question: "What do you call someone with no body and no nose?",
            answer: "Nobody knows. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
            isOpen: false,
        },
        {
            id: "4",
            question: "Why do you never see elephants hiding in trees?",
            answer: "Because they're so good at it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
            isOpen: false,
        },
    ];

    return (
        <div className="container my-5">
            <div className="text-center mb-4">
                <h2 className="fw-bold text-x">Frequently Asked Questions</h2>
                <p className="lead text-secondary">
                    Can’t find the answer you’re looking for? Reach out to our <a href="https://www.facebook.com/profile.php?id=61558092445373" className="text-x">customer support</a> team.
                </p>
            </div>
            
            <div className="accordion" id="faqAccordion">
                {faqs.map(faq => (
                    <div className="accordion-item" key={faq.id}>
                        <h2 className="accordion-header" id={`heading${faq.id}`}>
                            <button 
                                className={`accordion-button ${faq.isOpen ? '' : 'collapsed'}`} 
                                type="button" 
                                data-bs-toggle="collapse" 
                                data-bs-target={`#collapse${faq.id}`} 
                                aria-expanded={faq.isOpen} 
                                aria-controls={`collapse${faq.id}`}>
                                {faq.question}
                            </button>
                        </h2>
                        <div id={`collapse${faq.id}`} className={`accordion-collapse collapse ${faq.isOpen ? 'show' : ''}`} aria-labelledby={`heading${faq.id}`} data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                                {faq.answer}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQ;
