import axios from 'axios';
import {
    LOGOUT,
    GOOGLE_AUTH_SUCCESS,
    GOOGLE_AUTH_FAIL,
    FACEBOOK_AUTH_SUCCESS,
    FACEBOOK_AUTH_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    EMAIL_SUBSCRIBE_FAIL,
    EMAIL_SUBSCRIBE_SUCCESS,
    CONTACT_SUBMIT_FAIL,
    CONTACT_SUBMIT_SUCCESS
} from './types';

// Load user data
export const load_user = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config);
            dispatch({
                type: USER_LOADED_SUCCESS,
                payload: res.data
            });
        } catch (err) {
            dispatch({
                type: USER_LOADED_FAIL
            });
        }
    } else {
        dispatch({
            type: USER_LOADED_FAIL
        });
    }
};

// Google Authentication
export const googleAuthenticate = (state, code) => async dispatch => {
    if (state && code && !localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        const details = {
            'state': state,
            'code': code
        };

        const formBody = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?${formBody}`, config);
            dispatch({
                type: GOOGLE_AUTH_SUCCESS,
                payload: res.data
            });
            dispatch(load_user());
        } catch (err) {
            dispatch({
                type: GOOGLE_AUTH_FAIL
            });
        }
    }
};

// Facebook Authentication
export const facebookAuthenticate = (state, code) => async dispatch => {
    if (state && code && !localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        const details = {
            'state': state,
            'code': code
        };

        const formBody = Object.keys(details).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])).join('&');

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/o/facebook/?${formBody}`, config);
            dispatch({
                type: FACEBOOK_AUTH_SUCCESS,
                payload: res.data
            });
            dispatch(load_user());
        } catch (err) {
            dispatch({
                type: FACEBOOK_AUTH_FAIL
            });
        }
    }
};

// Check Authentication Status
export const checkAuthenticated = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({ token: localStorage.getItem('access') });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/verify/`, body, config);
            if (res.data.code !== 'token_not_valid') {
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                });
            } else {
                dispatch({
                    type: AUTHENTICATED_FAIL
                });
            }
        } catch (err) {
            dispatch({
                type: AUTHENTICATED_FAIL
            });
        }

    } else {
        dispatch({
            type: AUTHENTICATED_FAIL
        });
    }
};

// Login Action
export const login = (email, password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email, password });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, body, config);
        // Save tokens to local storage
        const access = res.data.access;
        const refresh = res.data.refresh;

        localStorage.setItem('access', access);
        localStorage.setItem('refresh', refresh);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });        
        dispatch(load_user());
    } catch (err) {
        dispatch({
            type: LOGIN_FAIL
        });
    }
};

// Logout Action
export const logout = () => async dispatch => {
    const accessToken = localStorage.getItem('access');
    const refreshToken = localStorage.getItem('refresh');

    if (accessToken || refreshToken) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${accessToken}`
            }
        };

        const body = JSON.stringify({
            refresh_token: refreshToken
        });

        try {
            // Invalidate both the access and refresh tokens via the backend (Djoser endpoint)
            await axios.post(`${process.env.REACT_APP_API_URL}/auth/logout/`, body, config);

            // Remove tokens from localStorage
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');

            // Dispatch the LOGOUT action to reset the state
            dispatch({
                type: LOGOUT
            });

            // Optional: Redirect user after logout
            window.location.href = '/';
        } catch (err) {
            console.error('Error logging out:', err);
        }
    } else {
        console.warn('No tokens found to log out.');
    }
};

// Google Logout
export const googleLogout = () => async dispatch => {
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/o/logout/`, {
            provider: 'google'
        });

        // Remove JWT tokens from localStorage
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');

        dispatch({
            type: LOGOUT
        });

        // Optional: Redirect user after Google logout
        window.location.href = '/';
    } catch (err) {
        dispatch({
            type: GOOGLE_AUTH_FAIL
        });
        console.error('Google logout failed:', err);
    }
};

// Facebook Logout
export const facebookLogout = () => async dispatch => {
    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/o/logout/`, {
            provider: 'facebook'
        });

        // Remove JWT tokens from localStorage
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');

        dispatch({
            type: LOGOUT
        });

        // Optional: Redirect user after Facebook logout
        window.location.href = '/';
    } catch (err) {
        dispatch({
            type: FACEBOOK_AUTH_FAIL
        });
        console.error('Facebook logout failed:', err);
    }
};

// Signup Action
export const signup = (first_name, last_name, email, password, re_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ first_name, last_name, email, password, re_password });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/`, body, config);
        dispatch({
            type: SIGNUP_SUCCESS,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: SIGNUP_FAIL
        });
    }
};

// Verify Account Activation
export const verify = (uid, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/activation/`, body, config);
        dispatch({
            type: ACTIVATION_SUCCESS,
        });
    } catch (err) {
        dispatch({
            type: ACTIVATION_FAIL
        });
    }
};

// Reset Password
export const reset_password = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password/`, body, config);
        dispatch({
            type: PASSWORD_RESET_SUCCESS
        });
    } catch (err) {
        dispatch({
            type: PASSWORD_RESET_FAIL
        });
    }
};

// Confirm Reset Password
export const reset_password_confirm = (uid, token, new_password, re_new_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`, body, config);
        dispatch({
            type: PASSWORD_RESET_CONFIRM_SUCCESS
        });
    } catch (err) {
        dispatch({
            type: PASSWORD_RESET_CONFIRM_FAIL
        });
    }
};

// Submit Contact Form
export const submitContactForm = (name, email, message) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ name, email, message });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/contact/`, body, config);
        dispatch({
            type: CONTACT_SUBMIT_SUCCESS
        });
    } catch (err) {
        dispatch({
            type: CONTACT_SUBMIT_FAIL
        });
    }
};

// Subscribe Email
export const subscribe = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ email });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/subscribe/`, body, config);
        dispatch({
            type: EMAIL_SUBSCRIBE_SUCCESS
        });
    } catch (err) {
        dispatch({
            type: EMAIL_SUBSCRIBE_FAIL
        });
    }
};
