import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

axios.defaults.withCredentials = true;

const getAuthConfig = () => {
  const token = localStorage.getItem('access');
  const csrfToken = getCSRFToken();
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token ? `JWT ${token}` : '',
      'X-CSRFToken': csrfToken,
      Accept: 'application/json',
    }
  };
};

const getCSRFToken = () => {
  const name = 'csrftoken';
  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(name))
    ?.split('=')[1];
  return cookieValue;
};

const extractData = (response) => response.data;

const initialState = {
  bookings: [],
  businessAdmins: [],
  bookingStatus: [],
  paymentStatus: [],
  status: 'idle',
  error: null,
};

export const fetchBusinessAdmins = createAsyncThunk(
  'bookings/fetchBusinessAdmins',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services/business/admins/`, getAuthConfig());
      if (response.data && Array.isArray(response.data.results)) {
        console.log("Fetched business admins: ", response.data.results);
        return response.data.results;
      } else {
        console.error('Unexpected data format for business admins:', response.data);
        return rejectWithValue('Unexpected data format for business admins');
      }      
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

export const fetchPaymentStatus = createAsyncThunk(
  'bookings/fetchPaymentStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services/booking/paymentstatus/`, getAuthConfig());
      if (response.data && Array.isArray(response.data.results)) {
        console.log("Fetched payment status: ", response.data.results);
        return response.data.results;
      } else {
        console.error('Unexpected data format for payment status:', response.data);
        return rejectWithValue('Unexpected data format for payment status');
      }       
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

export const fetchBookingStatus = createAsyncThunk(
  'bookings/fetchBookingStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services/booking/bookingstatus/`, getAuthConfig());
      if (response.data && Array.isArray(response.data.results)) {
        console.log("Fetched booking status: ", response.data.results);
        return response.data.results;
      } else {
        console.error('Unexpected data format for booking status:', response.data);
        return rejectWithValue('Unexpected data format for booking status');
      } 
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

export const fetchBookings = createAsyncThunk(
  'bookings/fetchBookings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services/bookings/`, getAuthConfig());
      console.log("Fetched bookings: ", extractData(response));
      return extractData(response);
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

export const createBooking = createAsyncThunk(
  'bookings/createBooking',
  async (bookingData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/services/bookings/create/`, bookingData, getAuthConfig());
      console.log("Created booking: ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

export const updateBooking = createAsyncThunk(
  'bookings/updateBooking',
  async ({ id, bookingData }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/services/bookings/${id}/`, bookingData, getAuthConfig());
      console.log("Updated booking: ", response.data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

export const deleteBooking = createAsyncThunk(
  'bookings/deleteBooking',
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/services/bookings/${id}/`, getAuthConfig());
      console.log("Deleted booking with ID:", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response?.data?.detail || error.message);
    }
  }
);

const bookingSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessAdmins.fulfilled, (state, action) => {
        state.businessAdmins = action.payload;
        state.status = 'idle';
      })
      .addCase(fetchBookingStatus.fulfilled, (state, action) => {
        state.bookingStatus = action.payload;
        state.status = 'idle';
      })
      .addCase(fetchPaymentStatus.fulfilled, (state, action) => {
        state.paymentStatus = action.payload;
        state.status = 'idle';
      })
      .addCase(fetchBookings.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBookings.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bookings = action.payload;
      })
      .addCase(fetchBookings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
        state.status = 'idle';
      })
      .addCase(createBooking.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createBooking.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bookings.push(action.payload);
        state.status = 'idle';
      })
      .addCase(createBooking.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(updateBooking.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateBooking.fulfilled, (state, action) => {
        state.status = 'succeeded';
        const index = state.bookings.findIndex(booking => booking.id === action.payload.id);
        if (index !== -1) {
          state.bookings[index] = action.payload;
        }
        state.status = 'idle';
      })
      .addCase(updateBooking.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(deleteBooking.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteBooking.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bookings = state.bookings.filter(booking => booking.id !== action.payload);
        state.status = 'idle';
      })
      .addCase(deleteBooking.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  }
});

export const { resetError } = bookingSlice.actions;
export default bookingSlice.reducer;
