import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { signup } from '../actions/auth';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { Google, Facebook } from 'react-bootstrap-icons';
import logo2 from '../assets/Logo/logo2.png';
import { toast } from 'react-toastify';

const Signup = ({ signup, isAuthenticated }) => {
    const [accountCreated, setAccountCreated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        re_password: ''
    });

    const { first_name, last_name, email, password, re_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        if (password === re_password) {
            signup(first_name, last_name, email, password, re_password);
            setAccountCreated(true);
        } else {
            toast.error("Passwords do not match!");
            setLoading(false);
        }
    };

    const continueWithGoogle = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}/google`);
            if (data.authorization_url) {
                window.location.replace(data.authorization_url);
            } else {
                throw new Error('Authorization URL not found');
            }
        } catch (err) {
            console.error('Error during Google login:', err);
            toast.error('Failed to initiate Google login. Please try again later.');
        }
    };

    const continueWithFacebook = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/auth/o/facebook/?redirect_uri=${process.env.REACT_APP_API_URL}/facebook`);
            if (data.authorization_url) {
                window.location.replace(data.authorization_url);
            } else {
                throw new Error('Authorization URL not found');
            }
        } catch (err) {
            console.error('Error during Facebook login:', err);
            toast.error('Failed to initiate Facebook login. Please try again later.');
        }
    };

    if (isAuthenticated) {
        return <Navigate to='/services' />;
    }
    if (accountCreated) {
        return <Navigate to='/login' />;
    }

    return (
        <div className="d-flex align-items-center justify-content-center min-vh-100 bg-light p-5">
            <div className="card shadow-sm p-4" style={{ width: '100%', maxWidth: '400px' }}>
                <div className="text-center mb-4">
                    <img
                        alt="Your Company"
                        src={logo2}
                        className="mb-3 img-fluid"
                        style={{ maxHeight: '80px' }}
                    />
                    <h2 className="h4 font-weight-bold">Create your account</h2>
                </div>

                <form onSubmit={onSubmit}>
                    <div className="mb-3">
                        <label htmlFor="first_name" className="form-label">First Name</label>
                        <input
                            id="first_name"
                            name="first_name"
                            type="text"
                            value={first_name}
                            onChange={onChange}
                            required
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="last_name" className="form-label">Last Name</label>
                        <input
                            id="last_name"
                            name="last_name"
                            type="text"
                            value={last_name}
                            onChange={onChange}
                            required
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="email" className="form-label">Email address</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={onChange}
                            required
                            className="form-control"
                            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                            title="Please enter a valid email address"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            value={password}
                            onChange={onChange}
                            required
                            minLength="8"
                            className="form-control"
                        />
                    </div>

                    <div className="mb-3">
                        <label htmlFor="re_password" className="form-label">Confirm Password</label>
                        <input
                            id="re_password"
                            name="re_password"
                            type="password"
                            value={re_password}
                            onChange={onChange}
                            required
                            minLength="8"
                            className="form-control"
                        />
                    </div>

                    <div className="d-grid">
                        <Button type="submit" className="w-100 btn btn-dark" disabled={loading}>
                        {loading ? 'Signing up...' : 'Sign Up'}</Button>
                    </div>
                </form>

                <div className="text-center my-3">
                    <span className="text-muted">Or continue with</span>
                </div>

                <div className="d-flex justify-content-between">
                    <Button
                        onClick={continueWithGoogle}
                        variant="outline-secondary"
                        className="w-100 me-2"
                        disabled={loading}
                    >
                        <Google className="me-2" /> Google
                    </Button>
                    <Button
                        onClick={continueWithFacebook}
                        variant="outline-secondary"
                        className="w-100"
                        disabled={loading}
                    >
                        <Facebook className="me-2" /> Facebook
                    </Button>
                </div>

                <p className="mt-3 mb-0 text-center">
                    Already have an account? <Link to="/login">Login</Link>
                </p>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { signup })(Signup);
