import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async thunk to fetch blog by slug
export const fetchBlogBySlug = createAsyncThunk(
    'blog/fetchBlogBySlug',
    async (slug, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blog/${slug}`);
            // Validate the response structure
            if (response.data && response.data.slug) {
                return response.data;
            } else {
                console.error('Expected a blog object but got:', response.data);
                return rejectWithValue('Unexpected data format for blog detail');
            }
        } catch (error) {
            console.error('Error fetching blog by slug:', error);
            return rejectWithValue(error.message);
        }
    }
);

// Slice
const blogDetailSlice = createSlice({
    name: 'blogDetail',
    initialState: {
        blog: null, // Ensure blog is initialized as null
        status: 'idle',
        error: null
    },
    reducers: {
        // Add synchronous reducers if needed
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBlogBySlug.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(fetchBlogBySlug.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.blog = action.payload;
            })
            .addCase(fetchBlogBySlug.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload || action.error.message;
            });
    }
});

export default blogDetailSlice.reducer;
