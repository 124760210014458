import React, { useEffect, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { facebookAuthenticate } from '../actions/auth';
import queryString from 'query-string';
import ServiceList from '../components/ServiceList';
import Spinner from '../components/Spinner'; // A spinner component to display while loading
import { toast } from 'react-toastify'; // Toast for notifications
import 'react-toastify/dist/ReactToastify.css'; // Toast CSS for styling

const Facebook = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    //const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [loading, setLoading] = useState(true); // Manage loading state
    const [error, setError] = useState(null); // Manage error state
    const [redirect, setRedirect] = useState(false); // Manage seamless redirects

    useEffect(() => {
        const values = queryString.parse(location.search);
        const state = values.state || null;
        const code = values.code || null;

        if (state && code) {
            // Trigger Facebook authentication and manage state
            dispatch(facebookAuthenticate(state, code))
                .then(() => {
                    toast.success("Facebook authentication successful! Redirecting...", {
                        position: "top-center", // Using string fallback for position
                        autoClose: 2000, // Auto-close after 2 seconds
                    });
                    setLoading(false);
                    setTimeout(() => setRedirect(true), 2500); // Delay before redirect
                })
                .catch(() => {
                    toast.error("Facebook authentication failed. Redirecting to login...", {
                        position: "top-center", // Using string fallback for position
                        autoClose: 4000, // Auto-close after 4 seconds for failure
                    });
                    setError("Facebook authentication failed. Redirecting to login...");
                    setLoading(false);
                    setTimeout(() => setRedirect(true), 4000); // Redirect after showing error
                });
        } else {
            setLoading(false); // If no state or code, stop loading
        }
    }, [location, dispatch]);

    // While loading, display a spinner with smooth animations
    if (loading) {
        return <Spinner />;
    }

    // If authentication fails, show error message with toast and on-screen text
    if (error) {
        return (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                {error}
            </div>
        );
    }

    // If authentication succeeds or redirect state is true, redirect to the dashboard
    if (redirect) {
        return <Navigate to="/login" />; // Redirect to login page on failure
    }

    // If authenticated, display the ServiceList component
    return (
        <div>
            <ServiceList />
        </div>
    );
};

export default Facebook;
